import React from 'react';
import { useGlobalContext } from '../../context'
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import MenuAppBar from '../../components/navBar';
import SignIn from './../Signin/signin';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';


const firebaseConfig = {
  apiKey: "AIzaSyAkKX-qlUeEax6Qp5PeO7qPoBVQ01RzvYI",
  authDomain: "rkv-one.firebaseapp.com",
  projectId: "rkv-one",
  storageBucket: "rkv-one.appspot.com",
  messagingSenderId: "865777080431",
  appId: "1:865777080431:web:7a29c0c43595dd08c5959a",
  measurementId: "G-97Y2JT7CNS"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);


function createData(date, category, correct, questions, pc) {
  return { date, category, correct, questions, pc};
}

let rows = [];


function Scores() {

  const { userAuth, userInfo, user, isUser, handleSignOut } = useGlobalContext();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  
  onAuthStateChanged(auth, user => {
    if (user) {
      userAuth(true);
      userInfo(user);
      setUserId(user.uid);
    } else {
      userAuth(false);
      userInfo(null);
      setUserId(null);
    }
  });

  async function loadScores() {
    const db = getFirestore();


    if (userId) {
      const q = query(collection(db, "quiz/game/" + userId), orderBy("timestamp", "desc"));

      var localData = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var timestamp = doc.data().timestamp.toDate();
        var date = timestamp.toLocaleDateString();
        var category = doc.data().category;
        var correct = doc.data().correct;
        var questions = doc.data().questions;
        var percentage = doc.data().percentage;
        var pc = percentage + "%";
        localData.push(createData(date, category, correct, questions, pc));
      });

      rows = localData;
      setDataLoaded(true);
    };

  }

  loadScores();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div>
      <MenuAppBar />
      <Container maxWidth="md" sx={{ marginTop: "20vh" }}>
        {dataLoaded ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Correct Answers</TableCell>
                  <TableCell align="right">Total Questions</TableCell>
                  <TableCell align="right">Correct (%)</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
                  
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="right">{row.category}</TableCell>
                  <TableCell align="right">{row.correct}</TableCell>
                  <TableCell align="right">{row.questions}</TableCell>
                  <TableCell align="right">{row.pc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        ): ( <CircularProgress sx={{ marginRight: "auto", marginLeft: "auto", display: "block" }} />)}
        
      </Container>
    </div>
  )
}

export default Scores;
