import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { getAuth, signOut } from "firebase/auth";
import { getFirestore, collection, addDoc, FieldValue, doc, getDoc, updateDoc } from "firebase/firestore";
import { Navigate } from 'react-router-dom';

const table = {
  gk: 9,
  film: 11,
  music: 12,
  science: 17,
  sports: 21,
  history: 23,
  politics: 24,
  comics: 29
}

const API_ENDPOINT = 'https://opentdb.com/api.php?'

const url = ''
const tempUrl =
  'https://opentdb.com/api.php?amount=10&category=21&difficulty=easy&type=multiple'
const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [waiting, setWaiting] = useState(true)
  const [user, setUser] = useState(null)
  const [isUser, setIsUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [index, setIndex] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [error, setError] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const fetchQuestions = async (url) => {
    setLoading(true)
    setWaiting(false)
    const response = await axios(url).catch((err) => console.log(err))
    if (response) {
      const data = response.data.results
      if (data.length > 0) {
        setQuestions(data)
        setLoading(false)
        setWaiting(false)
        setError(false)
      } else {
        setWaiting(true)
        setError(true)
      }
    } else {
      setWaiting(true)
    }
  }

  const nextQuestion = () => {
    setIndex((oldIndex) => {
      const index = oldIndex + 1
      if (index > questions.length - 1) {
        openModal()
        return 0
      } else {
        return index
      }
    })
  }

  const checkAnswer = (value) => {
    if (value) {
      setCorrect((oldState) => oldState + 1)
    }
    nextQuestion()
  }

  const userAuth = (authState) => {
    if (authState) {
      setIsUser(true)
    } else if (!authState) {
      setIsUser(false)
    }
  }

  const userInfo = (value) => {
    setUser(value);
  }

  async function saveScores(correctVal, questionsVal, categoryVal) {
    const db = getFirestore();

    const percentage = ((correctVal / questionsVal) * 100).toFixed(0);

    let timeStamp = new Date();

    try {
      const docRef = await addDoc(collection(db, "quiz/game/" + user.uid), {
        uid: user.uid,
        correct: correctVal,
        questions: questionsVal,
        category: categoryVal,
        percentage: percentage,
        timestamp: timeStamp
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    saveScores(correct, questions.length, currentCategory);
  }

  const closeModal = () => {
    setWaiting(true)
    setCorrect(0)
    setIsModalOpen(false)
    setCurrentCategory(null);
  }

  const handleSubmit = (amount, category, difficulty) => {
    const url = `${API_ENDPOINT}amount=${amount}&difficulty=${difficulty}&category=${table[category]}&type=multiple`;
    setCurrentCategory(category);
    fetchQuestions(url)
  }

  const handleSignOut = () => { 
    const auth = getAuth();
    signOut(auth).then(() => {
      setUser(null);
      setIsUser(false);
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleNavToScores = () => { 
    Navigate('/scores');
  }

  return (
    <AppContext.Provider
      value={{
        waiting,
        loading,
        questions,
        index,
        correct,
        error,
        isModalOpen,
        user,
        isUser,
        nextQuestion,
        checkAnswer,
        closeModal,
        handleSubmit,
        userAuth,
        userInfo,
        handleSignOut,
        handleNavToScores
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
