import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Quiz from './pages/Quiz/quiz.js';
import Scores from './pages/Scores/scores.js';
import Loading from './pages/loading';
import Error404 from './pages/error404/index.js';

function App() {
  return (
    <BrowserRouter>
     <Routes>
        <Route exact path="/" element={<Loading />} />
        <Route path="/home" element={<Quiz />} />
        <Route path="/scores" element={<Scores />} />
        <Route path="/home" element={<Quiz />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
