import React, { Component } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useGlobalContext } from '../../context';
import { useNavigate } from "react-router-dom";
import GoogleSigninBtn from './../../assets/img/btn_google_signin_dark_normal_web.png';


const firebaseConfig = {
  apiKey: "AIzaSyAkKX-qlUeEax6Qp5PeO7qPoBVQ01RzvYI",
  authDomain: "rkv-one.firebaseapp.com",
  projectId: "rkv-one",
  storageBucket: "rkv-one.appspot.com",
  messagingSenderId: "865777080431",
  appId: "1:865777080431:web:7a29c0c43595dd08c5959a",
  measurementId: "G-97Y2JT7CNS"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function SignIn() {

  const { userAuth, userInfo, user, isUser } = useGlobalContext();
  
  let navigate = useNavigate();

  const onClickSignIn = () => {
    // Google provider object is created here.
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    // using the object we will authenticate the user.
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential =
          GoogleAuthProvider.credentialFromResult(result);
        var token;
        if (credential !== null) {
          token = credential.accessToken || null;
        }

        navigate("/");

        // The signed-in user info.
        const user = result.user;
        userAuth(true);
        userInfo(user);
      })
      .catch(error => {
        navigate("/");
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
        // ...
      });
  };

  const imgStyle = {
    
  };
  
  return (
    <div>
      <img src={GoogleSigninBtn} style={{ marginLeft: "auto", marginRight: "auto", display: "block", marginTop: "40vh", width: "200px", cursor: "pointer"}} onClick={onClickSignIn} alt="Google Signin" />
    </div>
  );
}
export default SignIn;
