import React from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import QuizIcon from '@mui/icons-material/Quiz';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useGlobalContext } from '../../context'
import MenuAppBar from '../navBar';

const theme = createTheme();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SetupForm = () => {

  const [amount, setAmount] = React.useState(10);
  const [category, setCategory] = React.useState("sports");
  const [difficulty, setDifficulty] = React.useState("easy");

  const { handleSubmit } = useGlobalContext()

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit( amount, category, difficulty );
  }

  return (
    <div>
      <MenuAppBar />
      <main className="quiz-background">
        <section className='quiz quiz-small quiz-background'>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
              <CssBaseline />
              <Card variant="outlined" sx={{p:5, borderRadius: 2}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mx: "auto" }}>
                  <QuizIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{textAlign: 'center'}}>
                  Setup Quiz
                </Typography>

                <Box component="form" onSubmit={handleFormSubmit} noValidate sx={{ mt: 3 }}>
                  <InputLabel id="amount-select-label" sx={{width: 350, mt: 1 }}>Number of questions</InputLabel>
                  <Slider
                    aria-label="Amount"
                    required
                    id="amount"
                    defaultValue={10}
                    onChange={handleAmountChange}
                    value={amount}
                    valueLabelDisplay="auto"
                    step={5}
                    marks
                    min={5}
                    max={30}
                  />

                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel id="category-select-label-2">Category</InputLabel>
                    <Select
                      labelId="category-select-label-2" 
                      required
                      id="category"
                      label="Category"
                      defaultValue="sports"
                      value={category}
                      onChange={handleCategoryChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={'gk'}>General Knowledge</MenuItem>
                      <MenuItem value={'film'}>Film</MenuItem>
                      <MenuItem value={'music'}>Music</MenuItem>
                      <MenuItem value={'science'}>science & Nature</MenuItem>
                      <MenuItem value={'sports'}>Sports</MenuItem>
                      <MenuItem value={'history'}>History</MenuItem>
                      <MenuItem value={'politics'}>Politics</MenuItem>
                      <MenuItem value={'comics'}>Comics</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{mt: 3 }}>
                    <InputLabel id="diffculity-select-label-2">Difficulty</InputLabel>
                    <Select
                      labelId="diffculity-select-label-2"
                      required
                      id="diffculty"
                      label="Difficulty"
                      defaultValue="easy"
                      value={difficulty}
                      onChange={handleDifficultyChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={'easy'}>Easy</MenuItem>
                      <MenuItem value={'medium'}>Medium</MenuItem>
                      <MenuItem value={'hard'}>Hard</MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Start
                  </Button>
                </Box>
              </Card>
            </Container>
          </ThemeProvider>
        </section>
      </main>
    </div>
  )
}

export default SetupForm
