import React from 'react';
import { useGlobalContext } from '../../context'
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import SetupForm from '../../components/setupForm';
import Loading from '../../components/loading';
import Modal from '../../models/Modal';
import MenuAppBar from '../../components/navBar';
import SignIn from './../Signin/signin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'

const firebaseConfig = {
  apiKey: "AIzaSyAkKX-qlUeEax6Qp5PeO7qPoBVQ01RzvYI",
  authDomain: "rkv-one.firebaseapp.com",
  projectId: "rkv-one",
  storageBucket: "rkv-one.appspot.com",
  messagingSenderId: "865777080431",
  appId: "1:865777080431:web:7a29c0c43595dd08c5959a",
  measurementId: "G-97Y2JT7CNS"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

function App() {
  const {
    waiting,
    loading,
    questions,
    index,
    correct,
    nextQuestion,
    checkAnswer,
    userAuth,
    userInfo,
    user,
    isUser
  } = useGlobalContext()

  
  onAuthStateChanged(auth, user => {
    if (user) {
      userAuth(true);
      userInfo(user);
      console.log(user);
    } else {
      userAuth(false);
      userInfo(null);
      console.log("no user");
    }
  });

  if (!isUser) {
    return <SignIn />
  }
  else if (waiting) {
    return <SetupForm />
  };
  if (loading) {
    return <Loading />
  }

  const { question, incorrect_answers, correct_answer } = questions[index]
  // const answers = [...incorrect_answers, correct_answer]
  let answers = [...incorrect_answers]
  const tempIndex = Math.floor(Math.random() * 4)
  if (tempIndex === 3) {
    answers.push(correct_answer)
  } else {
    answers.push(answers[tempIndex])
    answers[tempIndex] = correct_answer
  }

  return (
    <div>
      <MenuAppBar />
      <main>
        <Modal />
        <Card variant="outlined" sx={{p:5, borderRadius: 2, textAlign: 'center', marginTop: '50px'}}>
          <p className='correct-answers'>
            correct answers : {correct}/{index}
          </p>
          <article className='container'>
            <h2 dangerouslySetInnerHTML={{ __html: question }} />
            <div className='btn-container'>
              {answers.map((answer, index) => {
                return (
                  <button
                    key={index}
                    className='answer-btn'
                    onClick={() => checkAnswer(correct_answer === answer)}
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                )
              })}
            </div>
          </article>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, width: 200, backgroundColor: '#ffb100', color: '#222', fontWeight: 'bold', alignSelf: 'center', "&:hover": { backgroundColor: '#805900', color: '#fff'} }}
            onClick={nextQuestion}
          >
            Next Question
          </Button>
        </Card>
      </main>
    </div>
  )
}

export default App
